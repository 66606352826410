import React from "react";
import { Box } from "theme-ui";
import { layout } from "styled-system";
import { styled } from "../../lib/styled";

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: size || "100%", width: size || "100%" }}
    // width={size + ""}
    // height={size + ""}
    fill="none"
    {...props}
  />
);

SVG.displayName = "SVG";

export default styled(SVG)`
  ${layout}
  justify-self: center;
`;
