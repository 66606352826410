import React from "react";
import Radio from "./Radio";
import styled from "@emotion/styled";
import Grid from "./Grid";

export default function Radios({
  labels,
  selectedLabel: selected,
  onChange,
  paddingRadio,
  vertical = false,
  Icons = [],
  ...props
}) {
  const onClick = (label, index) => {
    onChange(label, index);
  };
  return (
    <Wrapper
      gridTemplateColumns={[
        "repeat(1, 1fr)",
        vertical ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
      ]}
      width="100%"
      {...props}
    >
      {labels.map((label, index) => (
        <Radio
          padding={paddingRadio}
          key={label}
          label={label}
          onClick={onClick}
          index={index}
          Icon={Icons && Icons[index]}
          isSelected={selected === label}
        ></Radio>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  // :last-child {
  //   margin: 0px;
  // }
  // :first-child {
  //   margin: 0px;
  // }
`;
