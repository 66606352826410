import { motion } from "framer-motion";
import React from "react";

export const motions = {
  hover: () => {
    return {
      whileHover: { scale: 1.05 },
      whileTap: { scale: 0.95 },
    };
  },
  enter: () => {
    return {
      animate: { y: 0, opacity: 1 },
      initial: { y: 20, opacity: 0 },
      exit: { y: 5, opacity: 0, transition: { duration: 0.1 } },
    };
  },
};

export const withMotion = (BaseComponent, motionName) => (props) => {
  const FramerComponent = motion(BaseComponent);
  return <FramerComponent {...props} {...motions[motionName]()} />;
};
