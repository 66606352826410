import Link from "next/link";
import React, { useState, useEffect } from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import { styled } from "../lib/styled";
import {
  layout,
  grid,
  layoutProps,
  gridProps,
  flexbox,
  flexboxProps,
  space,
  spaceProps,
} from "styled-system";
import Text from "./Text";
import { ArrowRight, Check } from "./Icons";
import Flex from "./Flex";
import Aanbod from "./Icons/Aanbod";
import Spacer from "./Spacer";

type CardAanbodDefault = {
  name: string;
  price: string;
  list: string;
  faded?: boolean;
  pill?: boolean;
  listTitle: string;
  variants?: any;
};

type CardAanboxDescription = {
  name?: string;
  price?: string;
  list?: string;
  faded?: boolean;
  pill?: boolean;
  listTitle?: string;
  variants?: any;
};

export type CardAanbodProps = CardAanbodDefault | CardAanboxDescription;

function CardAanbod({
  name,
  price,
  list,
  faded = false,
  pill = false,
  listTitle,
  variants,
}: CardAanbodProps) {
  const description = list ? undefined : name
  const listArray = list ? list.split('\n') : undefined
  const priceSplitted = price ? price.includes('\n') ? price.split('\n') : price : undefined; 
  return (
    <Wrapper
      margin={["10px 0px", "0 10px"]}
      minHeight={["auto", "100%"]}
      faded={faded}
      description={description}
      width={["90%", "100%"]}
      variants={variants}
    >
      {description ? (
        <Text fontSize={[0, 1]} alignSelf="start">
          {description}
        </Text>
      ) : (
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          p={["15px"]}
        >
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
          >
            <p style={{ margin: "0px" }}>
              <Text
                fontSize={[2, 3]}
                fontWeight="600"
                alignSelf="start"
                color={faded ? "gray.1" : "black"}
              >
                {name.split("+")[0]}
              </Text>
              {name.split("+").length > 1 && (
                <Text
                  fontSize={[2, 3]}
                  fontWeight="600"
                  alignSelf="start"
                  color="pink.0"
                >
                  +
                </Text>
              )}
            </p>
            {/* {pill && (
              <Text
                borderRadius="5px"
                alignSelf="start"
                p="5px 10px"
                bg="pink.1"
                color="black"
                fontSize={[1]}
              >
                Populair
              </Text>
            )} */}
          </Flex>
          <Spacer mt="5px" />
          {typeof priceSplitted !== "object" ? (
            priceSplitted.length > 1 ? (
              <p style={{ margin: "0px" }}>
                <Text
                  fontSize={[3, 4]}
                  fontWeight="600"
                  alignSelf="start"
                  color={faded ? "gray.1" : "black"}
                >
                  {priceSplitted}
                </Text>
              </p>
            ) : (
              <Text
                fontSize={[3, 4]}
                fontWeight="600"
                alignSelf="start"
                color={faded ? "gray.1" : "black"}
              >
                {priceSplitted}
              </Text>
            )
          ) : (
            priceSplitted.map((price, index) => (
              <Text
                key={index}
                fontSize={[0, 1]}
                fontWeight="600"
                alignSelf="start"
                color={faded ? "gray.1" : "black"}
              >
                {price}
              </Text>
            ))
          )}
          <Spacer mt="15px" />
          <Text alignSelf="start">{listTitle}</Text>
          <Spacer mt="10px" />
          <UL>
            {listArray.map((name) => (
              <li key={name}>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  mb={[1]}
                >
                  <div style={{ flexShrink: 0 }}>
                    <Check size="14px" fill={false ? "none" : "#70D10F"} />
                  </div>
                  <Text
                    fontSize={[0]}
                    ml={[1]}
                    color={false ? "gray.1" : "black"}
                  >
                    {name}
                  </Text>
                </Flex>
              </li>
            ))}
          </UL>
        </Flex>
      )}
    </Wrapper>
  );
}

const UL = styled.div`
  list-style-type: none;
`;

type Wrapper = layoutProps & gridProps & spaceProps;

const Wrapper = styled(motion.div)<Wrapper>`
  ${layout}
  ${grid}
  ${space}
  border: ${({ faded, description }) =>
    faded || description ? "1px solid white" : "1px solid #161616"};
  background-color: white;
  border-radius: ${({ description }) => (description ? "0" : "10px")};
  box-shadow: ${({ description }) =>
    description ? "" : "0px 0px 15px rgba(0, 0, 0, 0.2)"};
`;

export default CardAanbod;
