import SVG from "./SVG";

export default function Check(props) {
  return (
    <SVG size="100%" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70711 14.2929L19 5L20.4142 6.41421L9.70711 17.1213L4 11.4142L5.41421 10L9.70711 14.2929Z"
        fill="black"
        {...props}
      />
    </SVG>
  );
}
