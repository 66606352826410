import Head from "next/head";
import Link from "next/link";
import { Button, Text, Flex, Grid } from "./";
import {
  Facebook,
  Instagram,
  Location,
  Logo,
  Mail,
  Phone,
  Waze,
} from "./Icons";
import styled from "@emotion/styled";
import { layout, LayoutProps } from "styled-system";
import Spacer from "./Spacer";
import Close from "./Icons/Close";

type Props = {
  children: React.ReactNode;
};

type ExtendedProps = Props & LayoutProps;
export default function Layout({
  children,
  cta = false,
  close = false,
  ...props
}: ExtendedProps) {
  return (
    <Wrapper>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="apple-touch-icon" href="/icon.png" />
        <meta name="theme-color" content="#fff" />
      </Head>
      <Grid
        m={[2, 3]}
        gridTemplateColumns={["1fr", "1fr auto 1fr"]}
        width="100%"
      >
        <div></div>
        <Flex mt={[3, 0]}>
          <Logo size={[40, 40, 45]} />
          <Text m={[2, 3]} variant="logo" fontSize={[2, 3]}>
            Diëtiste Annelies Naeyaert
          </Text>
        </Flex>
        {cta && (
          <Flex
            key="cta"
            mr={[0, 4]}
            justifySelf={["center", "right"]}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { type: "spring", duration: 1 },
            }}
            exit={{
              scale: 0.8,
              opacity: 0,
              transition: { type: "spring", duration: 0.2 },
            }}
          >
            <Link href="/afspraak" passHref>
              <Button>Maak een afspraak</Button>
            </Link>
          </Flex>
        )}
        {close && (
          <Flex
            mr={[0, 5, 6]}
            justifySelf={["center", "right"]}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { type: "spring", duration: 1 },
            }}
            exit={{
              scale: 0.8,
              opacity: 0,
              transition: { type: "spring", duration: 0.2 },
            }}
          >
            <Link href="/">
              <Flex style={{ cursor: "pointer" }}>
                <Close />
                <Text fontSize={[2, 3]} fontWeight="600" ml={[1]}>
                  Sluit
                </Text>
              </Flex>
            </Link>
          </Flex>
        )}
      </Grid>
      <Main {...props}>{children}</Main>
      <Spacer mt={[2, 3]} />
      <Grid
        as="footer"
        gridTemplateColumns={["auto", "auto", "repeat(4, 1fr)"]}
        gridColumnGap={["0px", "0px", "20px"]}
        gridRowGap={["15px", "15px", "0px"]}
        mb={[2, 1]}
      >
        <Flex justifyContent={["center"]}>
          <Link href="https://nl-nl.facebook.com/pg/praktijkannelies">
            <Flex style={{ cursor: "pointer" }} mx={[2]}>
              <Facebook />
            </Flex>
          </Link>
          <Link href="https://www.instagram.com/annelies.naeyaert">
            <Flex style={{ cursor: "pointer" }} mx={[2]}>
              <Instagram />
            </Flex>
          </Link>
        </Flex>
        <Flex>
          <Location />
          <Flex flexDirection="column" ml={[1]} alignItems="flex-start">
            <Flex>
              <Text
                alignSelf="center"
                fontSize={[0]}
                style={{ whiteSpace: "nowrap" }}
                mr="3px"
              >
                Praktijk Axis, Dorpsstraat 109, 8340 Sijsele
              </Text>
              <Link href="https://ul.waze.com/ul?ll=51.20174771%2C3.32331061&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location">
                <Flex style={{ cursor: "pointer" }}>
                  <Waze />
                </Flex>
              </Link>
            </Flex>
            <Flex>
              <Text
                alignSelf="center"
                fontSize={[0]}
                style={{ whiteSpace: "nowrap" }}
                mr="3px"
              >
                Tramstraat 41 bus 3, 8310 Assebroek (Brugge)
              </Text>
              <Link href="https://ul.waze.com/ul?ll=51.20267540%2C3.24545145&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location">
                <Flex style={{ cursor: "pointer" }}>
                  <Waze />
                </Flex>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Mail />
          <a href="mailto:annelies@sanamethode.be">
            <Text ml={[1]} textDecoration="underline">
              annelies@sanamethode.be
            </Text>
          </a>
        </Flex>
        <Flex justifyContent="center">
          <Phone />
          <Text ml={[1]}>0498/82.22.49</Text>
        </Flex>
      </Grid>
    </Wrapper>
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${layout}
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh);
  height: 100%;
  width: 100%;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
`;
