import {
  grid,
  position,
  space,
  color,
  layout,
  GridProps,
  PositionProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexBoxProps,
} from "styled-system";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
// or `import flexbox from '@styled-system/flexbox'`

type BaseProps = GridProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  LayoutProps;

const Flex = styled(motion.div)<BaseProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-auto-rows: 1fr;
  // grid-column-gap: 5px;
  ${grid}
  ${position}
  ${space}
  ${color}
  ${layout}
`;
export default Flex;
