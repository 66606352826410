import SVG from "./SVG";

export default function Waze(props) {
  return (
    <SVG size="20" {...props} viewBox="0 0 56 56">
      <g filter="url(#filter0_d)">
        <rect x="6" y="6" width="44" height="44" rx="4" fill="#7EC0EE" />
        <g clipPath="url(#clip0)">
          <path
            d="M26.62 37.4813C27.5567 37.4813 28.4934 37.5234 29.4302 37.4613C30.2825 37.4192 31.1793 37.3372 32.0117 37.1288C38.6309 35.5085 42.1691 28.2113 39.2746 22.0402C37.5254 18.3184 34.5288 16.1971 30.429 15.7604C27.2859 15.4279 24.4758 16.3633 22.1029 18.4824C19.7722 20.5816 18.5647 23.1995 18.4825 26.3382C18.4626 27.0653 18.4825 27.8145 18.4825 28.5416C18.4603 30.1243 17.5658 31.3501 16.0453 31.8266C16.0253 31.8266 15.9831 31.8688 15.9609 31.8688C16.5447 33.4071 18.9154 35.6725 20.3937 36.2333C22.2494 33.6975 25.994 34.593 26.62 37.4813Z"
            fill="white"
          />
          <path
            d="M26.5786 38.8721C26.3499 39.9117 25.7883 40.6809 24.8716 41.1575C23.7062 41.7604 22.5187 41.7405 21.4155 41.0334C20.2701 40.3063 19.7707 39.2046 19.855 37.8525C19.875 37.6242 19.8129 37.5399 19.6264 37.4358C17.6065 36.3762 16.0061 34.8999 14.9029 32.9049C14.6942 32.5104 14.5277 32.0937 14.4234 31.657C14.2769 31.054 14.5078 30.7637 15.1315 30.7016C15.8818 30.6174 16.5477 30.327 16.8806 29.6199C17.0271 29.3073 17.1093 28.9349 17.1093 28.5803C17.1514 27.687 17.1093 26.7937 17.1514 25.8981C17.3801 22.4468 18.8162 19.5807 21.4399 17.3353C23.8527 15.2605 26.6829 14.2852 29.8659 14.3871C35.4241 14.5955 40.1898 18.5034 41.313 23.9697C42.3319 28.9172 40.7936 33.0535 36.858 36.2742C36.6294 36.4604 36.3786 36.6489 36.1078 36.8151C35.9613 36.9193 35.9213 37.0013 35.9835 37.1675C36.5029 38.748 35.7748 40.4925 34.3187 41.2417C32.3409 42.2193 30.0524 41.2218 29.4486 39.1005C29.4287 39.0162 29.3243 38.9143 29.2622 38.9143C28.3898 38.8522 27.4931 38.8721 26.5786 38.8721ZM26.6207 37.4801C27.5574 37.4801 28.4942 37.5222 29.4309 37.4601C30.2833 37.418 31.18 37.336 32.0124 37.1276C38.6316 35.5073 42.1698 28.2101 39.2753 22.039C37.5262 18.3172 34.5296 16.1959 30.4298 15.7592C27.2866 15.4267 24.4765 16.3621 22.1036 18.4813C19.7729 20.5804 18.5654 23.1983 18.4833 26.337C18.4633 27.0641 18.4833 27.8133 18.4833 28.5404C18.4611 30.1231 17.5665 31.3489 16.046 31.8254C16.026 31.8254 15.9839 31.8676 15.9617 31.8676C16.5454 33.4059 18.9161 35.6713 20.3944 36.2321C22.2501 33.6963 25.9948 34.5918 26.6207 37.4801Z"
            fill="black"
          />
          <path
            d="M31.0106 32.0576C28.5533 31.9335 26.9307 30.956 25.8897 29.0031C25.6389 28.5243 25.8697 28.0477 26.3469 27.9435C26.6377 27.8815 26.9085 28.0898 27.1172 28.4223C27.388 28.8391 27.6588 29.2536 28.0117 29.5861C29.9673 31.4347 33.1726 30.854 34.4023 28.4644C34.5488 28.1741 34.7352 27.9457 35.0882 27.9457C35.6076 27.9657 35.9006 28.4866 35.672 28.9632C35.026 30.2732 34.0072 31.2286 32.6332 31.6852C32.0294 31.8914 31.4057 31.9734 31.0106 32.0576Z"
            fill="black"
          />
          <path
            d="M26.6643 25.2389C25.914 25.2389 25.3125 24.636 25.3125 23.889C25.3125 23.142 25.9163 22.5391 26.6643 22.5391C27.4124 22.5391 28.0161 23.142 28.0161 23.889C28.0183 24.6161 27.4146 25.2389 26.6643 25.2389Z"
            fill="black"
          />
          <path
            d="M36.1934 23.8665C36.1934 24.6158 35.5896 25.2165 34.8616 25.2386C34.1113 25.2386 33.5098 24.6557 33.5098 23.8887C33.5098 23.1395 34.0936 22.5388 34.8416 22.5166C35.5697 22.5166 36.1712 23.1195 36.1934 23.8665Z"
            fill="black"
          />
        </g>
        <rect
          x="6"
          y="6"
          width="44"
          height="44"
          rx="4"
          stroke="black"
          strokeWidth="3"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.5"
          y="0.5"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect
            width="27.2381"
            height="27.2381"
            fill="white"
            transform="translate(14.3809 14.3813)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
}
