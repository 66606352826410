import Link from "next/link";
import React, { useState, useEffect } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { styled } from "../lib/styled";
import {
  layout,
  grid,
  layoutProps,
  gridProps,
  flexbox,
  flexboxProps,
  space,
  spaceProps,
} from "styled-system";

const moveThreshold = 5;

function Carousel({ children, transition = "bounce", vertical = false, MINUTE_MS = 3000 }) {
  const [{ items, direction }, setItems] = useState({
    items: children.length ? [...children] : [children],
    direction: 1,
  });
  const [hovering, setHovering] = useState(false);
  const shiftArray = () => {
    const res = [...items];
    setItems({ items: [res.pop(), ...res], direction: 1 });
  };
  const unshiftArray = () => {
    const res = [...items];
    const firstItem = res.shift();
    setItems({ items: [...res, firstItem], direction: 0 });
  };

  useEffect(() => {
    if (!hovering && children.length > moveThreshold) {
      const interval = setInterval(() => {
        unshiftArray();
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  });
  useEffect(() => {
    children.length > moveThreshold && unshiftArray();
  }, []);
  const cnt = children.length > moveThreshold ? 4 : items.length;
  const transitionMotion = () => {
    if (transition === "interval") {
      return {
        duration: hovering ? 0.1 : 3,
        ease: "easeInOut",
      };
    }
    if (transition === "continious") {
      return {
        duration: hovering ? 0.1 : MINUTE_MS / 1000,
        ease: "linear",
      };
    }
    if (transition === "bounce") {
      return {
        type: "spring",
        mass: 1,
        stiffness: 300,
        damping: 60,
      };
    }
  };
  return (
    <motion.div
      style={wRapper}
      variants={container}
      initial="hidden"
      animate="show"
      exit="hidden"
    >
      <AnimateSharedLayout>
        <Wrapper
          display={["flex", "flex"]}
          flexDirection={["column", "row"]}
          width={["100%", "auto"]}
          alignItems="center"
          layout
          // onTap={(e, info) => {
          //   console.log(info.point.x > window.innerWidth / 2);
          //   info.point.x > window.innerWidth / 2
          //     ? unshiftArray()
          //     : shiftArray();
          // }}
          // onHoverStart={() => setHovering(true)}
          // onHoverEnd={() => {
          //   setHovering(false);
          // }}
        >
          {items.map((item, index) => (
            <Item
              variants={item}
              transition={transitionMotion()}
              layout={
                !(direction === 1 ? index === 0 : index === items.length - 1)
              }
              key={item.key}
              // height={["220px", "calc(" + 100 / cnt / 1.7 + "vw + 20px)"]}
              width={["80%", "calc(" + 100 / cnt + `vw - 40px)`]}
              mx={["0", "20px"]}
              my={["10px", "0px"]}
              maxWidth={["auto", "350px"]}

              // gridColumn={`${index + 1} / span 1`}
            >
              {item}
            </Item>
          ))}
        </Wrapper>
      </AnimateSharedLayout>
    </motion.div>
  );
}

type Item = layoutProps & gridProps & spaceProps;

const Item = styled(motion.div)<Item>`
  ${layout}
  ${grid}
  ${space}
  max-height: 200px;
  height: 100%;
`;

type Wrapper = flexboxProps & layoutProps;

const Wrapper = styled(motion.div)<Wrapper>`
  ${flexbox}
  ${layout}
  ${space}
`;

const wRapper = {
  display: "flex",
  // overflow: "hidden",
  justifyContent: "center",
  width: "100vw",
};

export default Carousel;

const container = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    transition: {
      type: "spring",
      duration: 0.2,
    },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      duration: 1,
      staggerChildren: 1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};
