import { withMotion } from "../theme/motion";
import {
  variant,
  VariantProps,
  FlexBoxProps,
  flexbox,
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  typographyProps,
  border,
  BorderProps,
  position,
  PositionProps,
} from "styled-system";
import { styled } from "../lib/styled";
import { motion } from "framer-motion";
// import { Text as TextThemed } from "theme-ui";

type TextProps = FlexBoxProps &
  VariantProps &
  SpaceProps &
  ColorProps &
  typographyProps &
  BorderProps &
  PositionProps;

const variants = {
  default: {
    color: "text",
    fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 2,
  },
  bold: {
    color: "text",
    fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 2,
    fontWeight: 500,
    lineHeight: "116%",
    letterSpacing: "-0.055em",
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: "0.2em",
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  logo: {
    fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    color: "text",
    fontSize: 3,
  },
  step: {
    fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: [3, 4],
    lineHeight: "116%",
    letterSpacing: "-0.055em",
  },
};

const Text = styled(motion.span)<TextProps>`
  color: inherit;
  align-self: center;
  ${variant({ variants })}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${({ textDecoration }) => `text-decoration: ${textDecoration};`}
`;

Text.defaultProps = {
  variant: "default",
};

export default Text;
