import { styled } from "../lib/styled";
import ImageNext, { ImageProps as ImageNextProps } from "next/image";
import { grid, gridProps, border, borderProps } from "styled-system";

type ImageProps = gridProps & borderProps & ImageNextProps;

export default styled(ImageNext)<ImageProps>`
  ${grid}
  ${border}
`;
