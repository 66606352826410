import SVG from "./SVG";

export default function Aanbod(props) {
  return (
    <SVG size="24" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.2037V15.8197L22 13.8197V10.1801L12 8.18011V2.79614L1.48145 11.9999L12 21.2037ZM10 16.7961L4.51863 11.9999L10 7.20368V9.81971L20 11.8197V12.1801L10 14.1801V16.7961Z"
        fill="black"
      />
    </SVG>
  );
}
