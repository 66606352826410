import SVG from "./SVG";

export default function Phone(props) {
  return (
    <SVG size="24" {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8565 8.3379C11.3746 7.63969 11.5604 6.90384 10.9744 6.25431C9.65949 4.41433 8.77515 3.27649 8.22044 2.72854C7.16589 1.6868 5.43112 1.82771 4.51779 2.72765C4.02723 3.21101 3.86116 3.377 3.35747 3.88861C0.551816 6.69577 2.26285 12.63 6.81139 17.183C11.3589 21.735 17.2926 23.4469 20.1041 20.6338C20.5689 20.1858 20.9624 19.792 21.2728 19.464C22.1678 18.518 22.3037 16.8597 21.2669 15.7824C20.7354 15.2301 19.6503 14.3885 17.7329 13.0169C17.1457 12.4918 16.4494 12.6056 15.811 13.0244C15.504 13.2259 15.2805 13.4297 14.8585 13.8521L14.0923 14.6187C13.9914 14.7197 12.621 14.0334 11.2907 12.7018C9.95972 11.3695 9.2739 9.99906 9.37435 9.89862L10.1411 9.13142C10.275 8.99742 10.339 8.93254 10.4211 8.84627C10.5921 8.66654 10.7337 8.50344 10.8565 8.3379ZM15.5057 16.0329L16.2721 15.2661C16.5044 15.0337 16.6549 14.8907 16.7773 14.7922C18.457 15.9984 19.4297 16.7568 19.8271 17.1697C20.0656 17.4176 20.0286 17.8699 19.8212 18.089C19.5342 18.3924 19.1613 18.7655 18.7037 19.2068C16.8857 21.0256 12.0959 19.6437 8.22513 15.7691C4.35315 11.8933 2.97188 7.10279 4.7762 5.29748C5.27786 4.78798 5.4368 4.62912 5.92035 4.15265C6.10166 3.97401 6.59552 3.93389 6.81608 4.15176C7.24314 4.57364 8.03534 5.58799 9.20071 7.21133C9.14038 7.28623 9.06503 7.37087 8.97333 7.46724C8.90603 7.53798 8.84996 7.59481 8.72738 7.71751L7.96136 8.48396C6.65821 9.787 7.76802 12.0047 9.87697 14.1157C11.9845 16.2253 14.203 17.3364 15.5057 16.0329Z"
        fill="black"
        {...props}
      />
    </SVG>
  );
}
