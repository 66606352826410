import SVG from "./SVG";

export default function Close(props) {
  return (
    <SVG size="20" {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 11.7677L4.63398 17.1339L2.86621 15.3661L8.23233 9.99997L2.86621 4.63386L4.63398 2.86609L10.0001 8.23221L15.3662 2.86609L17.134 4.63386L11.7679 9.99997L17.134 15.3661L15.3662 17.1339L10.0001 11.7677Z"
        fill="black"
      />
    </SVG>
  );
}
