import {
  flexbox,
  position,
  space,
  color,
  layout,
  grid,
  FlexboxProps,
  PositionProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
  GridProps,
} from "styled-system";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

type FlexProps = FlexboxProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  GridProps &
  LayoutProps;

const Flex = styled(motion.div)<FlexProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${flexbox}
  ${grid}
  ${position}
  ${space}
  ${color}
  ${layout}
`;

export default Flex;
