import React from "react";
import { withMotion } from "../theme/motion";
import styled from "@emotion/styled";
import Flex from "./Flex";
import Text from "./Text";
import { Check } from "./Icons";
import { space } from "styled-system";

const Wrapper = withMotion(
  styled(Flex)`
    ${space}
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    justify-content: space-between;
  `,
  "hover"
);

const StyledBox = styled(Flex)`
  flex-shrink: 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 2px;
  background: white;
  height: 20px;
  width: 20px;
  margin: 5px;
`;

function Radio({ label, onClick, isSelected, Icon, index, ...props }) {
  return (
    <Wrapper
      {...props}
      bg={isSelected ? "pink.1" : "white"}
      onClick={() => onClick(label, index)}
    >
      <Flex>
        {Icon && (
          <div style={{ flexShrink: 0 }}>
            <Icon />
          </div>
        )}
        <Text variant="bold" ml={[3]} mr={[2]}>
          {label}
        </Text>
      </Flex>
      <StyledBox>{isSelected && <Check />}</StyledBox>
    </Wrapper>
  );
}

export default Radio;
