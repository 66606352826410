import SVG from "./SVG";

export default function Logo(props) {
  return (
    <SVG size="100%" {...props} viewBox="0 0 54 54">
      <path
        d="M36.7198 15.98L36.1598 16.56L36.8198 18.62C37.5998 21.2 37.7398 24.12 37.1398 26.1C36.3398 28.76 34.1998 31.56 31.6998 33.2C30.7398 33.86 30.2998 34.3 29.8998 35.06C29.6198 35.6 29.4398 36.1 29.4998 36.16C29.8998 36.56 32.9998 34.76 34.7198 33.14C38.8598 29.28 40.4598 23.84 38.9998 18.58C38.4998 16.76 37.8798 15.4 37.5398 15.4C37.3998 15.4 37.0198 15.66 36.7198 15.98Z"
        fill="black"
      />
      <path
        d="M31.6403 20.32C30.0803 21.7 28.8003 22.9 28.8003 22.98C28.8003 23.08 28.5803 23.46 28.3003 23.86L27.8203 24.6L28.8203 25.54C30.1803 26.82 31.0203 28.58 31.1603 30.4C31.2003 31.16 31.3203 31.8 31.3803 31.8C31.6003 31.8 33.5203 29.94 34.0403 29.2C35.3803 27.34 36.1403 24.54 35.9403 22.22C35.8003 20.72 35.1403 18.36 34.7403 18.02C34.5203 17.86 33.8403 18.36 31.6403 20.32ZM33.3403 21.12C33.5403 21.66 33.0803 21.9 32.5003 21.52C32.2203 21.34 32.0403 21.1 32.1003 21C32.3003 20.68 33.2003 20.78 33.3403 21.12ZM34.0203 23.56C34.5803 24.02 34.4203 24.6 33.7203 24.6C33.0603 24.6 32.3003 23.96 32.4603 23.54C32.6203 23.1 33.4403 23.12 34.0203 23.56ZM30.0203 24.2C30.4803 24.46 30.5203 25 30.0803 25C29.6003 25 29.0003 24.62 29.0003 24.3C29.0003 23.96 29.4603 23.92 30.0203 24.2ZM33.0003 26.3C33.6403 26.64 33.8003 27.28 33.2803 27.48C32.6603 27.72 31.1403 26.54 31.5403 26.12C31.7603 25.9 32.4003 25.98 33.0003 26.3Z"
        fill="black"
      />
      <path
        d="M25.8602 19.9C24.1802 20.72 22.7202 22.2 22.4002 23.44C22.0602 24.64 22.1802 24.9 23.0602 24.86C24.9202 24.74 26.7402 23.02 27.2802 20.86C27.4602 20.14 27.5402 19.46 27.4602 19.38C27.3602 19.28 26.6602 19.52 25.8602 19.9ZM26.0002 21.24C26.0002 21.54 23.7402 23.8 23.4202 23.8C23.0202 23.8 23.8002 22.64 24.7802 21.78C25.6802 20.98 26.0002 20.84 26.0002 21.24Z"
        fill="black"
      />
      <path
        d="M17.9402 21.14C17.6202 21.44 17.8402 22 18.3602 22.28C19.3802 22.82 20.4002 24.16 20.4002 24.98C20.4002 25.26 20.5202 25.4 20.8002 25.4C21.3802 25.4 21.3202 24.8 20.6002 23.3C20.1802 22.42 19.7802 21.9 19.2002 21.52C18.3802 20.98 18.1602 20.92 17.9402 21.14Z"
        fill="black"
      />
      <path
        d="M23.7997 26.14C23.6397 26.2 23.1597 26.42 22.7397 26.62C21.4397 27.24 20.8997 27.26 19.6597 26.8C18.4197 26.34 17.4397 26.36 16.3997 26.9C14.9797 27.64 13.7997 30 14.0997 31.54C14.4997 33.74 16.5597 36.48 18.4797 37.38C19.5197 37.86 20.1997 37.84 21.3197 37.26C21.8797 36.96 22.0197 36.96 22.7197 37.24C23.7397 37.64 24.6797 37.5 25.7397 36.76C26.7597 36.08 27.3397 35.32 28.1797 33.66C29.6197 30.76 29.0397 28.1 26.6397 26.58C25.7397 26.02 24.6197 25.84 23.7997 26.14ZM16.6597 29.74C16.5997 30.86 16.6797 31.74 16.9597 32.84C17.3797 34.56 17.2197 34.86 16.4397 33.72C15.4997 32.36 15.3397 30.48 16.0197 29.06C16.2397 28.58 16.4997 28.2 16.5997 28.2C16.6797 28.2 16.7197 28.9 16.6597 29.74Z"
        fill="black"
      />
      <path
        d="M21.4592 38.92C21.3192 38.98 21.1992 39.16 21.1992 39.32C21.1992 39.56 21.3992 39.6 22.0592 39.56C22.6392 39.52 22.8992 39.4 22.8992 39.2C22.8992 38.9 21.9592 38.72 21.4592 38.92Z"
        fill="black"
      />
      <rect
        x="1.67162"
        y="7.15575"
        width="45"
        height="45"
        rx="3.5"
        transform="rotate(-7 1.67162 7.15575)"
        stroke="#EFAEAE"
        strokeWidth="3"
      />
    </SVG>
  );
}

export function Logo2() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.7198 15.98L36.1598 16.56L36.8198 18.62C37.5998 21.2 37.7398 24.12 37.1398 26.1C36.3398 28.76 34.1998 31.56 31.6998 33.2C30.7398 33.86 30.2998 34.3 29.8998 35.06C29.6198 35.6 29.4398 36.1 29.4998 36.16C29.8998 36.56 32.9998 34.76 34.7198 33.14C38.8598 29.28 40.4598 23.84 38.9998 18.58C38.4998 16.76 37.8798 15.4 37.5398 15.4C37.3998 15.4 37.0198 15.66 36.7198 15.98Z"
        fill="black"
      />
      <path
        d="M31.6403 20.32C30.0803 21.7 28.8003 22.9 28.8003 22.98C28.8003 23.08 28.5803 23.46 28.3003 23.86L27.8203 24.6L28.8203 25.54C30.1803 26.82 31.0203 28.58 31.1603 30.4C31.2003 31.16 31.3203 31.8 31.3803 31.8C31.6003 31.8 33.5203 29.94 34.0403 29.2C35.3803 27.34 36.1403 24.54 35.9403 22.22C35.8003 20.72 35.1403 18.36 34.7403 18.02C34.5203 17.86 33.8403 18.36 31.6403 20.32ZM33.3403 21.12C33.5403 21.66 33.0803 21.9 32.5003 21.52C32.2203 21.34 32.0403 21.1 32.1003 21C32.3003 20.68 33.2003 20.78 33.3403 21.12ZM34.0203 23.56C34.5803 24.02 34.4203 24.6 33.7203 24.6C33.0603 24.6 32.3003 23.96 32.4603 23.54C32.6203 23.1 33.4403 23.12 34.0203 23.56ZM30.0203 24.2C30.4803 24.46 30.5203 25 30.0803 25C29.6003 25 29.0003 24.62 29.0003 24.3C29.0003 23.96 29.4603 23.92 30.0203 24.2ZM33.0003 26.3C33.6403 26.64 33.8003 27.28 33.2803 27.48C32.6603 27.72 31.1403 26.54 31.5403 26.12C31.7603 25.9 32.4003 25.98 33.0003 26.3Z"
        fill="black"
      />
      <path
        d="M25.8602 19.9C24.1802 20.72 22.7202 22.2 22.4002 23.44C22.0602 24.64 22.1802 24.9 23.0602 24.86C24.9202 24.74 26.7402 23.02 27.2802 20.86C27.4602 20.14 27.5402 19.46 27.4602 19.38C27.3602 19.28 26.6602 19.52 25.8602 19.9ZM26.0002 21.24C26.0002 21.54 23.7402 23.8 23.4202 23.8C23.0202 23.8 23.8002 22.64 24.7802 21.78C25.6802 20.98 26.0002 20.84 26.0002 21.24Z"
        fill="black"
      />
      <path
        d="M17.9402 21.14C17.6202 21.44 17.8402 22 18.3602 22.28C19.3802 22.82 20.4002 24.16 20.4002 24.98C20.4002 25.26 20.5202 25.4 20.8002 25.4C21.3802 25.4 21.3202 24.8 20.6002 23.3C20.1802 22.42 19.7802 21.9 19.2002 21.52C18.3802 20.98 18.1602 20.92 17.9402 21.14Z"
        fill="black"
      />
      <path
        d="M23.7997 26.14C23.6397 26.2 23.1597 26.42 22.7397 26.62C21.4397 27.24 20.8997 27.26 19.6597 26.8C18.4197 26.34 17.4397 26.36 16.3997 26.9C14.9797 27.64 13.7997 30 14.0997 31.54C14.4997 33.74 16.5597 36.48 18.4797 37.38C19.5197 37.86 20.1997 37.84 21.3197 37.26C21.8797 36.96 22.0197 36.96 22.7197 37.24C23.7397 37.64 24.6797 37.5 25.7397 36.76C26.7597 36.08 27.3397 35.32 28.1797 33.66C29.6197 30.76 29.0397 28.1 26.6397 26.58C25.7397 26.02 24.6197 25.84 23.7997 26.14ZM16.6597 29.74C16.5997 30.86 16.6797 31.74 16.9597 32.84C17.3797 34.56 17.2197 34.86 16.4397 33.72C15.4997 32.36 15.3397 30.48 16.0197 29.06C16.2397 28.58 16.4997 28.2 16.5997 28.2C16.6797 28.2 16.7197 28.9 16.6597 29.74Z"
        fill="black"
      />
      <path
        d="M21.4592 38.92C21.3192 38.98 21.1992 39.16 21.1992 39.32C21.1992 39.56 21.3992 39.6 22.0592 39.56C22.6392 39.52 22.8992 39.4 22.8992 39.2C22.8992 38.9 21.9592 38.72 21.4592 38.92Z"
        fill="black"
      />
      <rect
        x="1.67162"
        y="7.15575"
        width="45"
        height="45"
        rx="3.5"
        transform="rotate(-7 1.67162 7.15575)"
        stroke="#EFAEAE"
        strokeWidth="3"
      />
    </svg>
  );
}

// export const buttons = {
//   primary: {
//     fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
//     fontWeight: "600",
//     p: 3,
//     py: 2,
//     m: 2,
//     borderRadius: 5,
//     cursor: "pointer",
//     color: "background",
//     bg: "pink700",
//     // "&:hover": {
//     //   bg: "text"
//     // }
//   },
//   secondary: {
//     color: "background",
//     bg: "secondary",
//   },
//   outlined: {},
// };
