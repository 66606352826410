import { withMotion } from "../theme/motion";
// import { Button as ButtonThemed } from "theme-ui";
import {
  variant,
  VariantProps,
  color,
  ColorProps,
  system,
  SystemProps,
  border,
  BorderProps,
} from "styled-system";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

type ButtonProps = VariantProps & ColorProps & SystemProps & BorderProps;

const cursor = system({
  prop: "cursor",
  cssProperty: "cursor",
});

const Button = styled(motion.button)<ButtonProps>(
  {
    appearance: "none",
    fontFamily: "inherit",
    whiteSpace: "nowrap",
  },
  variant({
    variants: {
      primary: {
        border: "none",
        fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
        fontWeight: "semiBold",
        p: 3,
        py: 2,
        m: 2,
        borderRadius: 5,
        cursor: "pointer",
        color: "white",
        bg: "pink.0",
        "&:focus": {
          outline: "none",
          // boxShadow: "0px 0px 0px 2px black",
        },
      },
      icon: {
        border: "none",
        fontFamily: "Laila, Helvetica Neue, Helvetica, Arial, sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // p: 3,
        // py: 2,
        // m: 2,
        borderRadius: 5,
        cursor: "pointer",
        color: "black",
        bg: "white",
        "&:focus": {
          outline: "none",
          // boxShadow: "0px 0px 0px 2px black",
        },
      },
      disabled: {
        color: "white",
        bg: "secondary",
      },
    },
  }),
  color,
  border
);

Button.defaultProps = {
  variant: "primary",
};

export default Button;
